import React, {Component} from 'react';
import {Typography} from '@material-ui/core';

class CeoMobile extends Component {

  render() {
    return (
      <div style={{margin: 20}}>
        <Typography variant="display1">CEO</Typography>
        <article style={{marginTop: 30, marginBottom: 50}}>
          <div>
            <div style={{marginRight: 180, textAlign: "center"}}>
              <img alt="아이앤북 CEO 이상훈" src="https://iandbook.s3.amazonaws.com/home/inb_ceo.png"
                   style={{width: "100%", pointerEvents: "none", borderRadius: 10}}/>
              <Typography variant="body2" style={{marginTop: 5}}>대표이사{" "}
                <strong>이 상 훈</strong></Typography>
            </div>
            <p/>
            <div>
              <Typography variant="caption">
                <Typography variant="subheading"><strong>수&emsp;&emsp;&emsp;&emsp;상</strong></Typography><p/>
                2018 21세기 대한민국을 빛낸 한국인물대상<br/>
                2010 대한출판문화협회 유공포상
              </Typography>
            </div>
            <p/>
            <div>
              <Typography variant="caption">
                <Typography variant="subheading"><strong>경&emsp;&emsp;&emsp;&emsp;력</strong></Typography>
                <p/>
                2019.06 ~ 중소기업중앙회 부회장<br/>
                2019.06 ~ 중소기업중앙회 남북경협위원회 위원<br/>
                2016.04 ~ 2019.05 중소기업중앙회 TV홈쇼핑상품추천위원회 위원<br/>
                2016.03 ~ 2019.05 중소기업중앙회 중소기업윤리위원회 위원<br/>
                2016.02 ~ 2019.05 중소기업중앙회 이사<br/>
                2015.11 ~ 2016.09 세계인쇄회의 조직위원회 위원<br/>
                2015.06 ~ 2019.05 중소기업중앙회 종이인쇄산업위원회 위원<br/>
                2015.04 ~ 2019.05 중소기업중앙회 구조개혁특별위원회 위원<br/>
                2014.02 ~ 한국제책공업협동조합 이사장<br/>
                2004.09 ~ 아이.앤.북 대표<br/>
              </Typography>
            </div>
          </div>
          <div style={{marginTop: 50}}>
            <Typography variant={"headline"} style={{display: "inline", marginRight: 10}}>아이.앤.북</Typography>
            <Typography variant={"h6"} style={{fontFamily: "GungsuhChe", lineHeight: 2, letterSpacing: 2, display: "inline"}}>
              은 제본 외길을 50년이상 묵묵히 걸어왔던 노하우를 가지고,
              고객에게 최고의 서비스를 제공하려 노력하고 있습니다.<br/>
              아이앤북 임직원 또한 고객의 편에서 고객의 입장으로, 고객을 편하게 해드린다는 목표 하나만을 가지고
              묵묵히 우리의 주어진 길을 걸어가고 있습니다.<br/>
              큰일, 작은일 일의 규모와 크기에 상관없이 고객의 잘됨이 우리의 잘됨과 직관적으로 연결되어 있다는 것을 인지하며
              고객을 편하게 해드린다는 일념으로 작은일에도 최선을 다하고 있습니다.<br/>
              책이 한권 나오려면 데이터작성, 인쇄부터 제본, 후가공 등 참 많은 과정을 거쳐야 합니다.
              이 과정과정 하나하나에 모두의 땀과 노력이 스며들어 있습니다.<br/>
              저희 아이앤북은 단순히 마지막 공정인 제본만 하면 된다는 피상적인 생각에서 벗어나
              책을 의뢰하신 고객의 입장에서 처음부터 끝까지 아무 사고 없이 좋은 품질의 책이 나올수 있도록
              어드바이스 제공을 통해 토탈솔루션을 제공하려 노력하고 있습니다.<br/>
              이런 토탈서비스를 제공하기 위해 출판 모든 과정을 두루 경험한 출중한 임직원들을 다수 보유하고 있고,
              새로 배우려는 젊은 직원들도 다수 있어 신.구 조합의 하모니로 아이.앤.북은 항상 활기가 넘치고 있습니다.<br/>
              작은 씨앗이 세월이 지나 큰 거목이 되듯이, 작은 인연의 씨앗을 키워 협력업체분들과 함께 성장해온 아이.앤.북.<br/>
              아이.앤.북은 우리가 만든 책으로 누군가는 꿈을 키워간다는 자부심으로 우리의 꿈도 키워가고 있습니다.
            </Typography>
          </div>
          <p/>
          <div style={{textAlign: "right"}}>
            <img alt="아이앤북 CEO 서명" src="https://iandbook.s3.amazonaws.com/home/inb_signiture.png"
                 style={{width: 200, pointerEvents: "none", borderRadius: 10, textAlign: "right"}}/>
          </div>
        </article>
      </div>
    );
  }
}

export default CeoMobile;