import React, {Component} from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import Typography from '@material-ui/core/Typography';


class NewsMobile extends Component {
  render() {
    return (
      <div style={{margin: 20}}>
        <Typography variant="display1">NEWS</Typography>
        <div style={{marginTop: 30}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{fontSize: "0.8em", padding: 5, textAlign: "center"}}>NO</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5, textAlign: "center"}}>제목</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5, textAlign: "center"}}>날짜</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>1</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>
                  <a href="https://www.seoul.co.kr/news/newsView.php?id=20221101025008"
                     target="_blank" rel="noopener noreferrer">
                    <label>[기고] 복합 경제위기 中企 대응력 길러 줘야/이상훈 중소기업중앙회 부회장</label>
                  </a>
                </TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>2022-10-31</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>2</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>
                  <a href="https://news.naver.com/main/read.nhn?mode=LSD&mid=sec&sid1=102&oid=018&aid=0004395582"
                     target="_blank" rel="noopener noreferrer">
                    <label>이상훈 한국제책공업협동조합 이사장, 중소기업중앙회 부회장 발탁</label>
                  </a>
                </TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>2019-06-05</TableCell>

              </TableRow>
              <TableRow>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>3</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>
                  <a href="http://www.etoday.co.kr/news/section/newsview.php?idxno=1309199" target="_blank"
                     rel="noopener noreferrer">
                    <label>아이앤북 이상훈 대표 멕시코 방문 경제사절단 동행</label>
                  </a>
                </TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>2016-03-28</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>4</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>
                  <a href="http://www.wowtv.co.kr/NewsCenter/News/Read?articleId=A201508310603&resource="
                     target="_blank" rel="noopener noreferrer">
                    <label>아이앤북 이상훈 대표 대통령 중국 순방 `경제사절단` 156명 확정</label>
                  </a>
                </TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>2015-08-31</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>5</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>
                  <a href="http://news.kbiz.or.kr/news/articleView.html?idxno=34900" target="_blank"
                     rel="noopener noreferrer">
                    <label>이상훈 제책조합 이사장 “정부·국민 ‘책 사랑’ 절실해”</label>
                  </a>
                </TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>2014-04-23</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>6</TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>
                  <a href="http://news.kbiz.or.kr/news/articleView.html?idxno=34492" target="_blank"
                     rel="noopener noreferrer">
                    <label>이상훈 아이앤북 대표 한국제책공업협동조합 신임 이사장으로 선임</label>
                  </a>
                </TableCell>
                <TableCell style={{fontSize: "0.8em", padding: 5}}>2014-03-19</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default NewsMobile;