import React, {Component} from 'react';

import {BrowserRouter as Router, Route, Link} from "react-router-dom";


import {decorate, observable, action} from "mobx";
import {observer} from "mobx-react";

import {AppBar, Button, IconButton, Divider, Typography, SwipeableDrawer} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';


import SideBar from './components/SideBar';
import TeamInfo from './components/TeamInfo';
import TeamInfoMobile from './components/TeamInfoMobile';
import News from './components/News';
import NewsMobile from './components/NewsMobile';
import ContactUs from './components/ContactUs';
import ContactUsMobile from './components/ContactUsMobile';
import Ceo from './components/Ceo';
import CeoMobile from './components/CeoMobile';
import Vision from './components/Vision';
import VisionMobile from './components/VisionMobile';
import Pics from './components/Pics';
import PicsMobile from './components/PicsMobile';

import logo from './inb_logo.png';

class App extends Component {

  isMobile = false;

  openMenu = false;

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this.isMobile = this.checkMobile();

    // console.log(this.isMobile);

    let component = this;

    window.addEventListener("resize", this.resize.bind(this, component));
  }

  clickLink = (e) => {
    window.scrollTo(0, 0);
  }

  clickMenuIcon(open, e) {
    this.openMenu = open;
  }


  resize(component, e) {
    component.isMobile = component.checkMobile();
  }

  checkMobile() {

    if (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPhone|iPod/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/IEMobile/i)
    ) {
      return true;
    } else if (document.body.clientWidth <= 600) {
      return true;
    }

    return false;
  }

  clickMenuIcon = (open) => {
    this.openMenu = open;
  }

  render() {
    return (
      <Router>
        <div>
          <section>
            {
              !this.isMobile &&
              <div>
                <header className="header">
                  <div style={{marginTop: 30, textAlign: "right"}}>
                    <Typography variant="headline"
                                style={{background: "rgba(255, 249, 192, 0.6)", padding: 10}}>
                      <i><strong>국내 <font style={{color: "#d50000", fontSize: "1.1em"}}>최대
                        최고</font></strong> 종합제책 전문기업&emsp;</i><strong
                      style={{fontSize: "1.2em"}}>아이.앤.북(I&BOOK) / 드림제이</strong>
                    </Typography>
                  </div>
                </header>
                <Divider/>
                <SideBar/>
                <div className="table-cell"
                     style={{verticalAlign: "top", width: "100%", paddingLeft: 50}}>
                  <div>
                    <Route
                      path={"/"}
                      exact
                      render={props => (
                        <Ceo/>
                      )}
                    />
                    <Route
                      path={"/team-info"}
                      exact
                      render={props => (
                        <TeamInfo/>
                      )}
                    />
                    <Route
                      path={"/news"}
                      exact
                      render={props => (
                        <News/>
                      )}
                    />
                    <Route
                      path={"/contact-us"}
                      exact
                      render={props => (
                        <ContactUs/>
                      )}
                    />
                    <Route
                      path={"/ceo"}
                      exact
                      render={props => (
                        <Ceo/>
                      )}
                    />
                    <Route
                      path={"/vision"}
                      exact
                      render={props => (
                        <Vision/>
                      )}
                    />
                    <Route
                      path={"/pics"}
                      exact
                      render={props => (
                        <Pics/>
                      )}
                    />
                  </div>
                </div>
              </div>
            }
            {
              this.isMobile &&
              <div>
                <AppBar className="header-mobile"
                        style={{backgroundColor: "transparent", position: "fixed"}}>
                  <div className="table" style={{padding: 10}}>
                    <div className="table-cell" style={{width: "90%"}}>

                      <i style={{color: "#000000"}}><strong>국내 <font
                        style={{fontSize: "1em", color: "#d50000"}}>최대 최고</font></strong> 종합제책 전문기업</i><p/>
                      <Typography style={{marginTop: 15}}><strong style={{fontSize: "1.5em"}}>아이.앤.북(I&BOOK)
                        / 드림제이</strong> </Typography>

                    </div>
                    <div className="table-cell" style={{textAlign: "right"}}>
                      <IconButton onClick={this.clickMenuIcon.bind(this, true)}>
                        <MenuIcon style={{color: "#000000", fontSize: "1.5em"}}/>
                      </IconButton>
                    </div>
                  </div>
                  <SwipeableDrawer
                    anchor="right"
                    open={this.openMenu}
                    onClose={this.clickMenuIcon.bind(this, false)}
                    onOpen={this.clickMenuIcon.bind(this, true)}
                  >
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={this.clickMenuIcon.bind(this, false)}
                      onKeyDown={this.clickMenuIcon.bind(this, false)}
                    >
                      <div style={{width: window.innerWidth * 0.8}}>
                        <Link to="/ceo"><img src={logo} alt="아이앤북 로고"
                                             style={{width: 280, borderRadius: 3}}
                                             onClick={this.clickLink}/></Link>
                        <p/>
                        <Divider/>
                        <Link className="link" to="/ceo"
                              style={{color: "#000000", textDecoration: "none"}}
                              onClick={this.clickLink}><Button variant="text"
                                                               style={{width: "100%"}}>
                          <Typography variant="h6">CEO</Typography></Button>
                        </Link>
                        <Divider/>
                        <Link className="link" to="/team-info"
                              style={{color: "#000000", textDecoration: "none"}}
                              onClick={this.clickLink}><Button variant="text"
                                                               style={{width: "100%"}}>
                          <Typography variant="h6">조직도</Typography>
                        </Button>
                        </Link>
                        <Divider/>
                        <Link className="link" to="/pics"
                              style={{color: "#000000", textDecoration: "none"}}
                              onClick={this.clickLink}><Button variant="text"
                                                               style={{width: "100%"}}>
                          <Typography variant="h6">회사전경</Typography></Button>
                        </Link>
                        <Divider/>
                        <Link className="link" to="/vision"
                              style={{color: "#000000", textDecoration: "none"}}
                              onClick={this.clickLink}><Button variant="text"
                                                               style={{width: "100%"}}>
                          <Typography variant="h6">강점 및 비전</Typography></Button>
                        </Link>
                        <Divider/>
                        <Link className="link" to="/news"
                              style={{color: "#000000", textDecoration: "none"}}
                              onClick={this.clickLink}><Button variant="text"
                                                               style={{width: "100%"}}>
                          <Typography variant="h6">News</Typography></Button>
                        </Link>
                        <Divider/>
                        <Link className="link" to="/contact-us"
                              style={{color: "#000000", textDecoration: "none"}}
                              onClick={this.clickLink}><Button variant="text"
                                                               style={{width: "100%"}}>
                          <Typography variant="h6">CONTACT US</Typography></Button>
                        </Link>
                        <Divider/>
                        <footer>
                          <div style={{padding: 10}}>
                            <Typography variant="caption"><strong>아이.앤.북
                              © {new Date().getFullYear()}</strong></Typography><p/>
                            <p/>
                            <Typography variant="caption">사업자등록번호 : <a target="_blank"
                                                                       rel="noopener noreferrer"
                                                                       href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1410119578">141-01-19578</a></Typography>
                            <Typography variant="caption">대표 : 이상훈</Typography>
                            <Typography variant="caption">주소 : 경기도 파주시 조리읍 황새말2길
                              24</Typography>
                            <Typography variant="caption">대표전화 : 031) 949-3300</Typography>
                            <Typography variant="caption">Fax : 031) 957-4128</Typography>
                            <Typography variant="caption">Email :
                              pololjh@naver.com</Typography>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </SwipeableDrawer>
                </AppBar>
                <div style={{marginTop: 100, overflowY: "auto"}}>
                  <Route
                    path={"/"}
                    exact
                    render={props => (
                      <CeoMobile/>
                    )}
                  />
                  <Route
                    path={"/team-info"}
                    exact
                    render={props => (
                      <TeamInfoMobile/>
                    )}
                  />
                  <Route
                    path={"/news"}
                    exact
                    render={props => (
                      <NewsMobile/>
                    )}
                  />
                  <Route
                    path={"/contact-us"}
                    exact
                    render={props => (
                      <ContactUsMobile/>
                    )}
                  />
                  <Route
                    path={"/ceo"}
                    exact
                    render={props => (
                      <CeoMobile/>
                    )}
                  />
                  <Route
                    path={"/vision"}
                    exact
                    render={props => (
                      <VisionMobile/>
                    )}
                  />
                  <Route
                    path={"/pics"}
                    exact
                    render={props => (
                      <PicsMobile/>
                    )}
                  />
                </div>
              </div>
            }
          </section>
          {/* <footer style={{ backgroundColor: "#4A4B4A" }}>
            <div style={{ height: 200, padding: 20 }}>
              <Typography ><strong>아이.앤.북 © {new Date().getFullYear()}</strong></Typography><p />
              <p />
              <Typography >사업자등록번호 : <a  target="_blank" rel="noopener noreferrer" href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1410119578">141-01-19578</a></Typography>
              <Typography >대표 : 이상훈</Typography>
              <Typography >주소 : 경기도 파주시 조리읍 황새말2길 24</Typography>
              <Typography >대표전화 : 031-949-3300</Typography>
            </div>
          </footer> */}
        </div>
      </Router>
    );
  }
}

decorate(App, {
  isMobile: observable,
  openMenu: observable,

  clickLink: action
})

export default observer(App);