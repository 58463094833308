import React, {Component} from 'react';

import Divider from '@material-ui/core/Divider';

import {Typography} from '@material-ui/core';

class ContactUs extends Component {
  render() {
    return (
      <div>
        <Typography variant="display1" style={{marginTop: 30}}>CONTACT US</Typography>
        <section style={{marginTop: 50}}>
          <div className="table">
            <div className="table-row">
              <div className="table-cell" style={{width: "50%", minWidth: 400, paddingRight: 50}}>
                <img alt="아이앤북 약도" src="https://iandbook.s3.amazonaws.com/home/inb_map.png"
                     style={{width: "100%", minWidth: 100, pointerEvents: "none", border: "solid 1px #424242"}}/>
                <div style={{textAlign: "right"}}><img alt="map"
                                                       src="https://iandbook.s3.amazonaws.com/home/naver_logo.png"
                                                       style={{width: 50, pointerEvents: "none"}}/>
                </div>
              </div>
              <div className="table-cell">
                <Typography variant="h5"><strong>주소</strong></Typography>
                <p/>
                <div>
                  <Typography variant="h6"><strong>1공장</strong></Typography>
                  <Typography variant="h6">경기도 파주시 조리읍 황새말2길 24</Typography>
                </div>
                <p/>
                <div>
                  <Typography variant="h6"><strong>2공장</strong></Typography>
                  <Typography variant="h6">경기도 파주시 조리읍 등원로 257</Typography>
                </div>
                <p/>
                <Divider/>
                <p/>
                <div>
                  <Typography variant="h5"><strong>대표전화</strong></Typography><p/>
                  <Typography variant="h6">031) 949-3300</Typography>
                </div>
                <p/>
                <Divider/>
                <p/>
                <div>
                  <Typography variant="h5"><strong>휴대전화</strong></Typography><p/>
                  <Typography variant="h6">
                    이주희 대표 / 010-8960-8577<br/>
                    정윤금 상무 / 010-3596-0511<br/>
                    한대오 과장 / 010-8811-8564<br/>
                    김형규 과장 / 010-8270-8049<br/>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {/* <p />
          <Divider />
          <p />
          <div style={{ width: 400 }}>
            <h3>대중교통 이용</h3>
            경의중앙선 금촌역에서 일반버스(10번, 333번), 마을버스(061번, 062번, 067번) 승차 후<p />
            오산2리.연당말 정류장 하차 후 도보 약 10분<p />
            <img alt="bus" src="https://iandbook.s3.amazonaws.com/home/inb_bus.png"
              style={{ width: "100%", pointerEvents: "none", border: "solid 1px #424242" }} />
            <div style={{ textAlign: "right" }}><img alt="map" src="https://iandbook.s3.amazonaws.com/home/naver_logo.png"
              style={{ width: 50, pointerEvents: "none" }} />
            </div>
          </div> */}
        </section>
      </div>
    )
  }
}

export default ContactUs;