import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

class TeamInfo extends Component {
  render() {
    return (
      <div style={{ margin: 20 }}>
        <Typography variant="display1">조직도</Typography>
        <article style={{ marginTop: 30, textAlign: "center" }}>
          <img alt="아이앤북 조직도" src="https://iandbook.s3.amazonaws.com/home/inb_team_m_new.png"
            style={{ width: "100%", pointerEvents: "none" }} />
        </article>
      </div>
    )
  }
}

export default TeamInfo;