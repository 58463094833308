import React, { Component } from 'react';

import { observer } from "mobx-react";

import Typography from '@material-ui/core/Typography';

class Pics extends Component {

  officePics = [
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/building.png",
      alt: "아이.앤.북 본사 전경",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/office.png",
      alt: "아이.앤.북 사무실",
    }
  ]

  linePics = [
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line1.png",
      alt: "아이.앤.북 공장라인1",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line2.png",
      alt: "아이.앤.북 공장라인2",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line3.png",
      alt: "아이.앤.북 공장라인3",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line4.png",
      alt: "아이.앤.북 공장라인4",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line5.png",
      alt: "아이.앤.북 공장라인5",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line6.png",
      alt: "아이.앤.북 공장라인6",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line7.png",
      alt: "아이.앤.북 공장라인7",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line8.png",
      alt: "아이.앤.북 공장라인8",
    },
    {
      filePath: "https://iandbook.s3.amazonaws.com/home/pics/line9.png",
      alt: "아이.앤.북 공장라인9",
    },
  ]

  render() {
    return (
      <div>
        <Typography variant="display1" style={{ marginTop: 30 }}>회사전경</Typography>
        <article style={{ marginRight: 50, marginTop: 50, marginBottom: 50, pointerEvents: "none" }}>
          <Typography variant="title" style={{ marginTop: 30 }}>본사 및 사무실</Typography>
          <p />
          <div>
            {
              this.officePics.map((data, index) => {
                return (
                  <img key={index} src={data.filePath} alt={data.alt} style={{ width: "45%", height: 300, marginLeft: 5, borderRadius: 10 }} />
                )
              })
            }
          </div>
          < Typography variant="title" style={{ marginTop: 30 }}> 현장 라인</Typography >
          <p />
          <div>

            {
              this.linePics.map((data, index) => {
                return (
                  <img key={index} src={data.filePath} alt={data.alt} style={{ width: "45%", height: 300, marginLeft: 5, borderRadius: 10 }} />
                )
              })
            }

          </div>
        </article >
      </div >
    )
  }
}

export default observer(Pics);