import React, { Component } from 'react';

import { decorate, action } from "mobx";
import { observer } from "mobx-react";

import { Link } from "react-router-dom";

import Button from '@material-ui/core/Button';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import logo from '../inb_logo.png';

class SideBar extends Component {

  clickLink = (e) => {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="table-cell" style={{ borderRight: "solid 1px #f4f4f4", height: window.innerHeight, backgroundColor: "#4A4B4A" }}>
        <div style={{ verticalAlign: "top", backgroundColor: "#ffffff" }}>
          <Link to="/ceo"><img src={logo} alt="아이앤북 로고" style={{ width: 280, borderRadius: 3 }} onClick={this.clickLink} /></Link>
          <p />
          <Divider />
          <Link className="link" to="/ceo" style={{ color: "#000000", textDecoration: "none" }}
            onClick={this.clickLink}><Button variant="text" style={{ width: "100%" }}>
              <Typography variant="h6">CEO</Typography></Button>
          </Link>
          <Divider />
          <Link className="link" to="/team-info" style={{ color: "#000000", textDecoration: "none" }}
            onClick={this.clickLink}><Button variant="text" style={{ width: "100%" }}>
              <Typography variant="h6">조직도</Typography>
            </Button>
          </Link>
          <Divider />
          <Link className="link" to="/pics" style={{ color: "#000000", textDecoration: "none" }}
            onClick={this.clickLink}><Button variant="text" style={{ width: "100%" }}>
              <Typography variant="h6">회사전경</Typography></Button>
          </Link>
          <Divider />
          <Link className="link" to="/vision" style={{ color: "#000000", textDecoration: "none" }}
            onClick={this.clickLink}><Button variant="text" style={{ width: "100%" }}>
              <Typography variant="h6">강점 및 비전</Typography></Button>
          </Link>
          <Divider />
          <Link className="link" to="/news" style={{ color: "#000000", textDecoration: "none" }}
            onClick={this.clickLink}><Button variant="text" style={{ width: "100%" }}>
              <Typography variant="h6">News</Typography></Button>
          </Link>
          <Divider />
          <Link className="link" to="/contact-us" style={{ color: "#000000", textDecoration: "none" }}
            onClick={this.clickLink}><Button variant="text" style={{ width: "100%" }}>
              <Typography variant="h6">CONTACT US</Typography></Button>
          </Link>
          <Divider />
        </div>
        <footer>
          <div style={{ padding: 20 }}>
            <Typography style={{ color: "#ffffff" }}><strong>아이.앤.북 © {new Date().getFullYear()}</strong></Typography><p />
            <p />
            <Typography style={{ color: "#ffffff" }}>사업자등록번호 : <a style={{ color: "#ffffff" }} target="_blank" rel="noopener noreferrer" href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1410119578">141-01-19578</a></Typography>
            <Typography style={{ color: "#ffffff" }}>대표 : 이상훈</Typography>
            <Typography style={{ color: "#ffffff" }}>주소 : 경기도 파주시 조리읍 황새말2길 24</Typography>
            <Typography style={{ color: "#ffffff" }}>대표전화 : 031) 949-3300</Typography>
            <Typography style={{ color: "#ffffff" }}>Fax : 031) 957-4128</Typography>
            <Typography style={{ color: "#ffffff" }}>Email : pololjh@naver.com</Typography>
          </div>
        </footer>
      </div>
    );
  }
}

decorate(SideBar, {
  clickLink: action
})

export default observer(SideBar);