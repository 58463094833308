import React, { Component } from 'react';
import { Typography, Divider } from '@material-ui/core';

class Vision extends Component {
  render() {
    return (
      <div>
        <Typography variant="display1" style={{ marginTop: 30 }}>강점 및 비전</Typography>
        <div className="table" style={{ marginBottom: 100, marginTop: 50, width: "100%" }}>
          <article className="table-cell">
            <div style={{ margin: 30 }}>
              <Typography variant="title" style={{ marginBottom: 30 }}><strong>강점</strong></Typography>
              <Typography variant="body1">1. 출판분야의 풍부한 네트워크</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">2. 기계의 최첨단화</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">3. 업계30년이상의 경력을 가지신 훌륭한 임직원 다수</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">4. 무선 3대(주,야간) / 접지 9대(주,야간) 로 납기 준수</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">5. 고객분들에게 1:1 담당직원 배정하여  실시간 진행상황 공유</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">6. 다수의 자체차량 보유로 인쇄물 수송 및 납기일 준수</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">7. 업계 유일한 제본진행 자체 프로그램 제작 및 사용 ( 웹 , 어플 )</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">8. 프로그래머 협력으로 지속적인 IT화 기획 (사고방지 및 진행편의성 연구)</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">9. 업계 최대 인력보유</Typography>
              <p />
              <Divider />
            </div>
          </article>
          <article className="table-cell" style={{ paddingRight: 100 }}>
            <div style={{ margin: 30 }}>
              <Typography variant="title" style={{ marginBottom: 30 }}><strong>Vision 2022</strong></Typography>
              <p />
              <Typography variant="body1">1. 업계를 선도할 젊은인력 교육 및 발굴업계를 선도할 젊은인력 교육 및 발굴</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">2. 진행과 현장의 IT화</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">3. 현장 안전 강화</Typography>
              <p />
              <Divider />
              <p />
              <Typography variant="body1">4. 협력업체들과 장기적인 비전 공유 및 네트워크 공고화</Typography>
              <p />
              <Divider />
            </div>
          </article>
        </div>
      </div>
    );
  }
}

export default Vision;